import React, { useState } from 'react'
import { StateCallBack } from 'react-multi-carousel'
import ImageWebp from 'src/components/ImageWebp'
import SectionComponent from 'src/components/SectionComponent'
import DefaultCarousel from 'src/components/UI/Carousels/DefaultCarousel'
import useWidth from 'src/hooks/window/useWidth'
import { WIDTH_MD, WIDTH_SM } from 'src/utils/breakpoints'
import { ContainerCarousel } from './style'
import CTAButton from '../components/CTAButton/_index'

type CardT = {
  id: string;
  title: string;
  imgSrc: string;
  imgAlt: string;
}

const CONTEUDO_CARDS = [
  {
    id: 'card_passo_01',
    title: '1 - Abra o app e clique em investir',
    imgSrc: 'https://central-imagens.bancointer.com.br/images-without-small-versions/carteira-1/image.webp',
    imgAlt: 'Home do Super App do Inter',
  },
  {
    id: 'card_passo_02',
    title: '2 - Na sessão "Investir" clique em Carteira Inteligente ou em "Ver Todos".',
    imgSrc: 'https://central-imagens.bancointer.com.br/images-without-small-versions/carteira2-mockup/image.webp',
    imgAlt: 'Área de investimentos nacionais do Super App do Inter',
  },
  {
    id: 'card_passo_03',
    title: '3 - Pronto, agora siga as instruções e contrate a carteira inteligente.',
    imgSrc: 'https://central-imagens.bancointer.com.br/images-without-small-versions/carteira-3/image.webp',
    imgAlt: 'Área da Carteira Administrada Inteligente do Super App do Inter',
  },
]

const CONTEUDO_TEXTO = {
  h2: 'O melhor app para investir fácil, rápido e seguro!',
  paragrafo_1: 'Acompanhe o passo a passo de como ativar a carteira automatizada.',
  cta: 'Quero conhecer',
}

const CONTEUDO_DATA_LAYER = {
  section: 'dobra_07',
  section_name: CONTEUDO_TEXTO.h2,
}

const MelhorAppParaInvestir = () => {
  const width = useWidth()
  const [ carouselIndex, setCarouselIndex ] = useState<number>(0)

  const handleSlideChange = (_: number, state: StateCallBack): void => setCarouselIndex(state.currentSlide)

  return (
    <SectionComponent
      id='melhor-app-para-investir'
      SectionStyles='py-5 align-items-md-center col-12'
      minHeight={{ sm: '379px', md: '427px', lg: '463px', xl: '571px', xxl: '613px' }}
    >
      <ContainerCarousel className='col-12 col-md-6'>
        <h2 className='font-citrina text-grayscale--500 fw-700 fs-28 lh-33 fs-lg-40 lh-lg-48 fs-xl-48 lh-xl-57 mb-3 w-100'>{CONTEUDO_TEXTO.h2}</h2>
        <p className='fs-14 text-grayscale--500 lh-17 fs-lg-16 lh-lg-20 fs-xl-18 lh-xl-21 mb-0'>{CONTEUDO_TEXTO.paragrafo_1}</p>
        <DefaultCarousel
          sm={{ items: 1, slidesToSlide: 1 }}
          md={{ items: 1, slidesToSlide: 1 }}
          lg={{ items: 1, slidesToSlide: 1 }}
          xl={{ items: 1, slidesToSlide: 1 }}
          customDotColor='#FF7A00'
          afterChange={handleSlideChange}
        >
          {CONTEUDO_CARDS.map((card: CardT) => (
            <div key={card.id}>
              <h3 className='fw-600 fs-16 lh-19 text-grayscale--500'>{card.title}</h3>
              {width <= WIDTH_SM && <ImageWebp pathSrc={card.imgSrc} arrayNumbers={[ 312 ]} altDescription={card.imgAlt} />}
            </div>
        ))}
        </DefaultCarousel>
        <div className='col-12 d-flex justify-content-left mt-4 px-0'>
          <CTAButton conteudoDataLayer={CONTEUDO_DATA_LAYER}>
            {CONTEUDO_TEXTO.cta}
          </CTAButton>
        </div>
      </ContainerCarousel>

      {width >= WIDTH_MD && (
        <div className='col-md-6 d-flex justify-content-center'>
          <ImageWebp pathSrc={CONTEUDO_CARDS[carouselIndex].imgSrc} arrayNumbers={[ 312, 336, 376, 432, 432 ]} altDescription={CONTEUDO_CARDS[carouselIndex].imgAlt} />
        </div>
      )}

    </SectionComponent>
  )
}

export default MelhorAppParaInvestir

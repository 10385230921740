import useWidth from 'src/hooks/window/useWidth'
import React from 'react'
import { WIDTH_MD } from 'src/styles/breakpoints'
import { QRCodeDispatchT, useQRCodeModal } from '../../../context/QRCodeModalContext'
import PrimaryButton from '../PrimaryButton/_index'
import PrimaryLinkButton from '../PrimaryLinkButton/_index'

type ConteudoDataLayerT = {
  section: string;
  section_name: string;
}

const CTAButton = ({ children, conteudoDataLayer }: { children: string; conteudoDataLayer: ConteudoDataLayerT }) => {
  const width = useWidth()
  const { setIsModalOpen }: QRCodeDispatchT = useQRCodeModal()

  return width < WIDTH_MD ? (
    <div className='mt-2 w-100'>
      <PrimaryLinkButton
        href='https://inter-co.onelink.me/Qyu7/kidxvb9g'
        dataLayer={conteudoDataLayer}
      >
        {children}
      </PrimaryLinkButton>
    </div>
  )
  : (
    <PrimaryButton
      dataLayer={conteudoDataLayer}
      onClickHandler={() => setIsModalOpen(true)}
    >
      {children}
    </PrimaryButton>
  )
}

export default CTAButton

import React from 'react'
import FAQ from 'src/components/Faq'
import BaseLayout from 'src/layouts/BaseLayout'

import pageContext from './pageContext.json'
import AcompanheOBlog from './sections/acompanhe-o-blog/_index'
import AproveiteBeneficiosCarteiraInteligente from './sections/aproveite-beneficios-carteira-inteligente/_index'
import ComoMontarCarteira from './sections/como-montar-carteira/_index'
import ConhecaInterAsset from './sections/conheca-inter-asset/_index'
import EstatisticasCarteiraInteligente from './sections/estatisticas-carteira-inteligente/_index'
import HeroCarteiraInteligente from './sections/hero-carteira-inteligente/_index'
import PorqueInvestirCarteiraInteligente from './sections/porque-investir-carteira-inteligente/_index'
import MelhorAppParaInvestir from './sections/melhor-app-para-investir/_index'
import Disclaimer from './sections/disclaimer/_index'
import QRCodeModalProvider from './context/QRCodeModalContext'
import qrCodeCarteiraInteligente from './assets/images/qr-code-investimentos-carteirainteligente.png'

const CONTEUDO_MODAL_CARTEIRA_INTELIGENTE = {
  title: 'Acesse a Carteira Inteligente',
  instructions: 'Aponte a câmera do celular para o QR Code abaixo que você será direcionado para a carteira inteligente do Inter.',
  qrCode: qrCodeCarteiraInteligente,
}

const CarteiraInteligente = () => {
  const { title, instructions, qrCode }: { title: string; instructions: string; qrCode: string } = CONTEUDO_MODAL_CARTEIRA_INTELIGENTE

  return (
    <BaseLayout pageContext={pageContext}>
      <QRCodeModalProvider
        title={title} instructions={instructions} qrCode={qrCode}
      >
        <HeroCarteiraInteligente />
        <ComoMontarCarteira />
        <PorqueInvestirCarteiraInteligente />
        <AproveiteBeneficiosCarteiraInteligente />
        <ConhecaInterAsset />
      </QRCodeModalProvider>

      <EstatisticasCarteiraInteligente />
      <QRCodeModalProvider
        title={title} instructions={instructions} qrCode={qrCode}
      >
        <MelhorAppParaInvestir />
      </QRCodeModalProvider>
      <div className='container pb-4 pb-md-5 pt-lg-5'>
        <div className='row mt-xl-5'>
          <div className='col-12 mb-4'>
            <h2 className='font-citrina text-grayscale--500 fs-20 fw-700 lh-33 fs-md-24 lh-md-30 fs-xl-40 lh-md-50 text-grayscale--500 mb-md-3'>
              Dúvidas frequentes sobre a Carteira Inteligente
            </h2>
          </div>
          <FAQ answerData={pageContext.structuredData.faq} columns={{ sm: 1, md: 1, lg: 1, xl: 1 }} />
        </div>
      </div>
      <AcompanheOBlog />
      <Disclaimer />
    </BaseLayout>
  )
}

export default CarteiraInteligente
